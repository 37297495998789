/**
 * @ag-grid-community/core - Advanced Data Grid / Data Table supporting Javascript / Typescript / React / Angular / Vue
 * @version v29.2.0
 * @link https://www.ag-grid.com/
 * @license MIT
 */
export var RowHighlightPosition;
(function (RowHighlightPosition) {
    RowHighlightPosition[RowHighlightPosition["Above"] = 0] = "Above";
    RowHighlightPosition[RowHighlightPosition["Below"] = 1] = "Below";
})(RowHighlightPosition || (RowHighlightPosition = {}));
